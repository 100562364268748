<template>
  <div>
    <div class="bg-gray">
      <div class="container-fluid">
        <div class="user-card">
          <h2>Files</h2>
          <div class="row">
            <div class="col-sm-4 col-sm-offset-4">
              <!-- File input -->
              <input class="form-control" type="file" @change="handleFileChange" />
              <input class="form-control" type="text" v-model="name" placeholder="File name" />
              <button class="btn btn-primary" style="margin-top: 10px;" @click="uploadFile">
                Upload
              </button>

              <!-- Current Folder Path -->
              <h4>Current Folder: {{ currentFolder || "Root" }}</h4>

              <!-- Image Preview -->
              <div v-if="imagePreview" class="image-preview mt-4">
                <h5>Image Preview</h5>
                <img :src="imagePreview" alt="Preview" class="img-fluid" style="width: 250px; height: 250px;" />
              </div>

              <!-- Folder and File Table -->
              <div>
                <h5>Contents</h5>
                <table class="table table-bordered table-hover">
                  <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th v-if="!foldersOnly">Size</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- Navigate Up -->
                  <tr v-if="currentFolder">
                    <td>
                      <a href="#" class="text-primary" @click="navigateUp">.. (Go up)</a>
                    </td>
                    <td>Folder</td>
                    <td v-if="!foldersOnly">-</td>
                    <td></td>
                  </tr>

                  <!-- Folders -->
                  <tr v-for="folder in folders" :key="folder">
                    <td>
                      <a href="#" class="text-primary" @click="browseFolder(folder)">{{ folder }}</a>
                    </td>
                    <td>Folder</td>
                    <td v-if="!foldersOnly">-</td>
                    <td>
                      <button class="btn btn-info btn-sm" @click="browseFolder(folder)">Open</button>
                    </td>
                  </tr>

                  <!-- Files -->
                  <tr v-for="file in files" :key="file.Key">
                    <td>{{ file.Key }}</td>
                    <td>File</td>
                    <td v-if="!foldersOnly">{{ formatFileSize(file.Size) }}</td>
                    <td>
                      <button class="btn btn-success btn-sm" @click="downloadFile(file)">View</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { StoreActions } from "./store/actions";
import StoreIndex from "./store/_StoreIndex";
import { StoreState } from "@/components/swiftspace/uploadFile/store/state";

export default {
  name: "UploadFile",
  data() {
    return {
      selectedFile: null,
      name: "",
      currentFolder: "",
      foldersOnly: false,
      imagePreview: null // Holds the image preview URL
    };
  },
  mounted() {
    this.fetchFiles(); // Fetch files and folders on mount
  },
  methods: {
    handleFileChange(event) {
      this.selectedFile = event.target.files[0]; // Get the selected file
    },
    commit(path, value) {
      this.$store.commit(`${StoreIndex.path}/${path}`, value);
    },
    dispatch(path, value) {
      this.$store.dispatch(`${StoreIndex.path}/${path}`, value);
    },
    uploadFile() {
      if (this.name.length === 0 || this.selectedFile == null) {
        return;
      }

      const uploadPath = this.currentFolder ? `${this.currentFolder}${this.name}` : this.name;

      this.dispatch(StoreActions.upload, {
        file: this.selectedFile,
        name: uploadPath
      });
      this.selectedFile = null;
      this.name = "";
    },
    fetchFiles(folder = "") {
      this.dispatch(StoreActions.getFiles, folder);
    },
    browseFolder(folder) {
      // Append the folder to the current path and fetch files
      const newPath = this.currentFolder ? `${this.currentFolder}${folder}` : folder;
      this.fetchFiles(newPath);
    },
    navigateUp() {
      // Navigate up to the parent folder
      const parentPath = this.currentFolder.split("/").slice(0, -2).join("/") + "/";
      this.fetchFiles(parentPath);
    },
    formatFileSize(size) {
      if (size < 1024) return `${size} B`;
      if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    },
    downloadFile(file) {
      console.log(file)
      this.imagePreview = ""
      // Fetch the signed URL and display the image preview
      this.dispatch(StoreActions.getSignedDownloadUrl, {
        key: file.Key,
        callback: (url) => {
          this.imagePreview = url; // Set the image preview URL
        }
      });
    }
  },
  computed: {
    ...mapState(StoreIndex.path, [StoreState.files, StoreState.folders])
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-header {
  background: #46c3f0;
  color: white;
}
a {
  color: black;
  font-weight: bold;
}

.btn-secondary {
  background: lightgray;
  border-color: lightgray;
}

@media (max-width: 700px) {
  .mobile-hide {
    display: none;
  }
}

@media (min-width: 701px) {
  .large-hide {
    display: none;
  }
}

.user-card {
  background: white;
  margin: 20px;
  border-radius: 10px;
}

.user-img {
  border-radius: 10px;
  margin: 10px;
}

label {
  color: gray;
  font-weight: bold;
}

.user-photo {
  width:100px;
  height:100px;
  border-radius: 50px;
  object-fit: cover;
}

@media (max-width:767px) {
  .user-photo {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
}
</style>