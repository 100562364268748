export const StoreState = {
    results:'results',
    page: 'page',
    files: 'files',
    folders: 'folders'
}

export const state = {
    [StoreState.results]:[],
    [StoreState.files]:[],
    [StoreState.folders]:[],
    [StoreState.page]: 0
}
