// import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/components/login/Login.vue"
import Home from "@/components/home/Home.vue"

import SwiftSpaceReportedUsers from '@/components/swiftspace/reportedUsers/ReportedUsers.vue';
import SwiftSpaceUser from '@/components/swiftspace/user/User.vue'
import ReportedBusinesses from "@/components/swiftspace/reportedBusinesses/ReportedBusinesses.vue";
import ReportedEvents from "@/components/swiftspace/reportedEvents/ReportedEvents.vue";
import ReportedMes from "@/components/swiftspace/reportedMes/ReportedMes.vue";
import PendingBusinesses from "@/components/swiftspace/pendingBusinesses/PendingBusinesses.vue";
import PendingEvents from "@/components/swiftspace/pendingEvents/PendingEvents.vue";
import Business from "@/components/swiftspace/business/Business.vue";
import Event from "@/components/swiftspace/event/Event.vue";
import Me from "@/components/swiftspace/me/Me.vue";
import EventSearch from "@/components/swiftspace/eventSearch/EventSearch.vue";
import BusinessSearch from "@/components/swiftspace/businessSearch/BusinessSearch.vue";
import MeSearch from "@/components/swiftspace/meSearch/MeSearch.vue";
import AwardAnswersSearch from "@/components/swiftspace/awardAnswersSearch/AwardAnswersSearch.vue";
import ThemeSearch from "@/components/swiftspace/themeSearch/ThemeSearch.vue";
import AcronymSearch from "@/components/swiftspace/acronymSearch/AcronymSearch.vue";
import PlaylistSearch from "@/components/swiftspace/playlistSearch/PlaylistSearch.vue";
import PendingWallpapers from "@/components/swiftspace/pendingWallpapers/PendingWallpapers.vue";
import WallpaperSearch from "@/components/swiftspace/wallpaperSearch/WallpaperSearch.vue";
import AwardSearch from "@/components/swiftspace/awardsSearch/AwardSearch.vue";
import Posts from "@/components/swiftspace/posts/Posts.vue";
import FlaggedMes from "@/components/swiftspace/flaggedMes/FlaggedMes.vue";
import ReportedWallpapers from "@/components/swiftspace/reportedWallpapers/ReportedWallpapers.vue";
import UsersByLocation from "@/components/swiftspace/usersByLocation/UsersByLocation.vue";
import StatusSearch from "@/components/swiftspace/statusSearch/StatusSearch.vue";
import RecentBackgrounds from "@/components/swiftspace/recentBackgrounds/RecentBackgrounds.vue";
import FlaggedBackgrounds from "@/components/swiftspace/flaggedBackgrounds/FlaggedBackgrounds.vue";
import Upload from "@/components/swiftspace/uploadFile/Upload.vue";


export default {
    router:new VueRouter({
        routes:[
            { path: '/', component: Login, name:'root' },
            { path: '/login', component: Login, name:'login' },
            { path: '/home', component: Home, name:'home' },

            { path: '/users/reported', component: SwiftSpaceReportedUsers, name:'SwiftSpaceReportedUsers' },
            { path: '/users/locations', component: UsersByLocation, name:'UsersByLocation' },
            { path: '/users/:id', component: SwiftSpaceUser, name:'SwiftSpaceUser' },

            { path: '/businesses/reported', component: ReportedBusinesses, name:'ReportedBusinesses' },
            { path: '/businesses/pending', component: PendingBusinesses, name:'PendingBusinesses' },
            { path: '/businesses/search', component: BusinessSearch, name:'BusinessSearch' },
            { path: '/businesses/:id', component: Business, name:'Business' },

            { path: '/events/reported', component: ReportedEvents, name:'ReportedEvents' },
            { path: '/events/pending', component: PendingEvents, name:'PendingEvents' },
            { path: '/events/search', component: EventSearch, name:'EventSearch' },
            { path: '/events/:id', component: Event, name:'Event' },

            { path: '/me/reported', component: ReportedMes, name:'ReportedMes' },
            { path: '/me/flagged', component: FlaggedMes, name:'FlaggedMes' },
            { path: '/me/search', component: MeSearch, name:'MeSearch' },
            { path: '/me/:id', component: Me, name:'Me' },

            { path: '/awards/answers/recent', component: AwardAnswersSearch, name:'AwardAnswersSearch' },
            { path: '/awards/search', component: AwardSearch, name:'AwardSearch' },

            { path: '/themes', component: ThemeSearch, name:'ThemeSearch' },

            { path: '/statuses', component: StatusSearch, name:'StatusSearch' },

            { path: '/acronyms', component: AcronymSearch, name:'AcronymSearch' },

            { path: '/playlists', component: PlaylistSearch, name:'PlaylistSearch' },

            { path: '/posts', component: Posts, name:'Posts' },

            { path: '/wallpapers/pending', component: PendingWallpapers, name:'PendingWallpapers' },
            { path: '/wallpapers/reported', component: ReportedWallpapers, name:'ReportedWallpapers' },
            { path: '/wallpapers', component: WallpaperSearch, name:'WallpaperSearch' },

            { path: '/backgrounds/flagged', component: FlaggedBackgrounds, name:'FlaggedBackgrounds' },
            { path: '/backgrounds/recent', component: RecentBackgrounds, name:'RecentBackgrounds' },

            { path: '/files/upload', component: Upload, name:'Upload' },
        ]
    })
}