// mutation constants

import {StoreState as StoreState} from "./state";

export const StoreMutations = {
    SET_RESULTS:'SET_RESULTS',
    SET_PAGE: 'SET_PAGE',
    SET_FILES: 'SET_FILES',
    SET_FOLDERS: 'SET_FOLDERS'
};

export const mutations = {
    [StoreMutations.SET_RESULTS](state,value) {
        state[StoreState.results] = value
    },
    [StoreMutations.SET_FILES](state,value) {
        state[StoreState.files] = value
    },
    [StoreMutations.SET_FOLDERS](state,value) {
        state[StoreState.folders] = value
    },
    [StoreMutations.SET_PAGE](state,value) {
        state[StoreState.page] = value
    },
};