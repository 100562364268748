// action constants
import Vue from 'vue'
import { StoreMutations } from './mutations';
import SwiftSpaceRepository from '../../../../api/SwiftSpaceRepository';
import axios from 'axios';

export const StoreActions = {
    upload: 'upload',
    getFiles: 'getFiles',
    clearState:'clearState',
    getSignedDownloadUrl: 'getSignedDownloadUrl'
};

export const actions = {
    [StoreActions.upload](context, form) {
        console.log(form)
        SwiftSpaceRepository.signedUploadUrl(form.name).then(data => {
            const url = data.url;
            axios.put(url, form.file, {
                headers: {
                    'Content-Type': 'binary/octet-stream',
                },
            }).then(() => {
                console.log("uploaded")
            }).catch(err => {
                Vue.prototype.$notify({'type':'danger','content':err})
            });
        }).catch(err => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.getFiles](context, folder) {
        SwiftSpaceRepository.browseFiles(folder).then(data => {
            const files = data.files || [];
            const folders = data.folders || [];

            context.commit(StoreMutations.SET_FILES, files);
            context.commit(StoreMutations.SET_FOLDERS, folders);
        }).catch(err => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    [StoreActions.getSignedDownloadUrl](contxt, request) {
        const key = request.key
        const callback = request.callback

        SwiftSpaceRepository.signedDownloadUrl(key).then(data => {
            callback(data.url);
        }).catch(err => {
            Vue.prototype.$notify({'type':'danger','content':err})
        });
    },
    clearState(context) {
        context.commit(StoreMutations.SET_RESULTS,[]);
        context.commit(StoreMutations.SET_FILES,[]);
        context.commit(StoreMutations.SET_FOLDERS,[]);
        context.commit(StoreMutations.SET_PAGE,0);
    }
};
