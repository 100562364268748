<template>
  <div>
    <modal :value="showing" @hide="$emit('close')" @input="$emit('close')" title="Create Award" :footer="false">
      <div class="modal-body">
        <input class="form-control" v-model="name" placeholder="Name" style="margin-top: 10px"/>
        <select class="form-control" v-model="type" style="margin-top: 10px">
          <option value="Poll">Poll</option>
          <option value="Ranking">Ranking</option>
        </select>

        <div style="margin-top: 10px">
          <input type="checkbox" v-model="allowCustom" id="allowCustom" />
          <label for="allowCustom">Allow Custom</label>
        </div>

        <dropdown class="form-group" style="margin-top: 10px">
          <div class="input-group">
            <input v-model="date" class="form-control" type="text" />
            <div class="input-group-btn">
              <Btn class="dropdown-toggle"
              ><i class="glyphicon glyphicon-calendar"></i
              ></Btn>
            </div>
          </div>
          <template #dropdown>
            <li>
              <DatePicker v-model="date" />
            </li>
          </template>
        </dropdown>

        <input class="form-control" v-model="bgPhoto" placeholder="Background Photo" style="margin-top: 10px"/>

        <award-options-form :options="options" @addOption="addOption" @remove="removeOption" />

      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" @click="create()">Create</button>
      </div>
    </modal>
  </div>
</template>

<script>

import AwardOptionsForm from "@/components/swiftspace/awardsSearch/AwardOptionsForm.vue";

export default {
  components: {
    AwardOptionsForm
  },
  data() {
    return {
      name: "",
      type: "Poll",
      date: "",
      options: [],
      nextOption: "",
      id: null,
      bgPhoto: null,
      allowCustom: false
    }
  },
  props: {
    showing: {
      default: false
    },
    existing: {
      default: null
    }
  },
  methods: {
    addOption(option) {
      this.options.push(option)
    },
    removeOption(index) {
      this.options.splice(index, 1)
    },
    create() {
      this.$emit('create', {
        name: this.name,
        type: this.type,
        startDate: this.date,
        options: this.options,
        id: this.id,
        bgPhoto: this.bgPhoto,
        allowCustom: this.allowCustom
      });
      this.$emit('close');
    }
  },
  watch: {
    showing(newVal) {
      if(newVal) {
        if(this.existing) {
          this.name = this.existing.name
          this.type = this.existing.type
          this.date = this.existing.date
          this.options = this.existing.options
          this.id = this.existing.id
          this.bgPhoto = this.existing.bgPhoto
          this.allowCustom = this.existing.allowCustom
        }
      }
    }
  }
};
</script>

<style scoped>
.modal-body {
  padding: 15px;
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 15px;
  border-top: 1px solid #e9ecef;
}

.option-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.option-text {
  margin: 0;
  text-align: left;
  flex-grow: 1;
}

.option-item button {
  margin-left: 10px;
}
</style>
